import React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import cogoToast from "cogo-toast";

const StyledTableCell = styled(TableCell)({
    "&.MuiTableCell-root": {
        fontWeight: "bold",
    },
});

export default function RequestLogsTable(props) {
    const {
        page,
        rowsPerPage,
        totalRecords,
        handlePageChange,
        handleRowsPerPageChange,
    } = props;

    const navigate = useNavigate();
    const onPageChange = (event, newPage) => {
        handlePageChange(newPage);
    };

    const onRowsPerPageChange = (event) => {
        handleRowsPerPageChange(parseInt(event.target.value, 10));
        handlePageChange(0);
    };

    const renderColumn = (row, column) => {
        switch (column.id) {
            case "action":
                return <span>{row.action}</span>;
            case "request":
                return (
                    <Box display="flex" alignItems="flex-end">
                        <Typography
                            variant="body1"
                            sx={{
                                maxWidth: '480px',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {JSON.stringify(row.request)}
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer', ml: 1 }}
                            color="primary"
                            variant="body1"
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(row.request))
                                cogoToast.success("Copied")
                            }}
                        >
                            Copy
                        </Typography>
                    </Box>
                );
            case "response":
                return (
                    <Box display="flex" alignItems="flex-end">
                        <Typography
                            variant="body1"
                            sx={{
                                maxWidth: '480px',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {JSON.stringify(row.response)}
                        </Typography>
                        <Typography
                            sx={{ cursor: 'pointer', ml: 1 }}
                            color="primary"
                            variant="body1"
                            onClick={() => {
                                navigator.clipboard.writeText(JSON.stringify(row.response))
                                cogoToast.success("Copied")
                            }}
                        >
                            Copy
                        </Typography>
                    </Box>
                );
            default:
                break;
        }
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props?.columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        backgroundColor: "#4B92E7",
                                        color: "#fff",
                                    }}
                                    className="font-medium"
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row, index) => {
                            return (
                                <TableRow
                                    style={{ cursor: "pointer" }}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {props.columns.map((column, idx) => {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {renderColumn(row, column)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Paper>
    );
}
