export const LOGISTICS = {
    "ondc.shiprocket.in": "shiprocket",
    "ondc.api.flipkart.com": "flipkart",
    "api.grab.in": "grab",
    "ondc.loadshare.net": "loadshare",
    "ondc.delhivery.com": "delhivery",
    "ondc.dunzo.in": "dunzo",
    "flash-api.shadowfax.in": "shadowfax",
    "shopify-hoop-seller.thewitslab.com": "shopify-hoop-seller",
    "ondc.qwqer.in": "qwqer",
    "ondc.shipyaari.com": "shipyaari",
    "ondc.xbees.in": "xbees",
    "webapi.magicpin.in/oms_partner/ondc/logistics": "magicpin",
    "bpp.tranze.digiit.ai": "tranze",
    "ondc.whizzard.in": "whizzard",
    "ondc-lsp.olacabs.com": "olacabs",
    "prod.shipdh.in": "shipdh",
    "bpp.iway.digiit.ai": "iway",
    "apis.delcaper.com": "delcaper",
    "ondc.ecomexpress.in": "ecomexpress",
    "ondc.theporter.in": "theporter",
    "my.ithinklogistics.com/ondc/prod": "ithinklogistics",
    "prod.ondc.adloggs.com": "adloggs",
    "ondc.pidge.in": "pidge",
    "ondc.zypp.app": "zypp",
    "pxapi.dtdc.in/api/dtdc": "dtdc",
    "bpp.ulip.digiit.ai": "ulip",
    "ondc.telyport.com": "telyport"
}