import React from "react";
import Button from "./Button";
import styled from "@emotion/styled/macro";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "2px !important",
    height: "35px !important",
    "& fieldset": {
      borderColor: "d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#1890ff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1890ff",
    },
  },
});
const formControlStyles = {
  height: "35px !important",
  width: "238px !important",
  "& .MuiInputLabel-root": {
    fontSize: "12px !important",
    transform: "translate(14px, 8px) scale(1)",
    "&.Mui-focused, &.MuiFormLabel-filled": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  "& .MuiSelect-select": {
    fontSize: "12px !important",
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    height: "35px !important",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-root": {
    height: "35px !important",
    "& fieldset": {
      borderColor: "#d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#1890ff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1890ff",
    },
  },
};

const FilterComponent = (props) => {
  const { fields = [], state, stateHandler, onFilter, onReset } = props;

  return (
    <div className=" mb-4 items-center mt-8">
      <div className="flex">
        <div className="flex items-end flex-wrap">
          {fields.map((item, index) => {
            return (
              <span style={{ marginRight: 2 }} key={index}>
                {item.type === "select" ? (
                  <FormControl fullWidth required={item.required} sx={formControlStyles}>
                    <InputLabel>{item.placeholder}</InputLabel>
                    <Select
                      value={state[item.id] || ""}
                      onChange={(e) =>
                        stateHandler({
                          ...state,
                          [item.id]: e.target.value,
                        })
                      }
                    >
                      {item.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <CssTextField
                    type="input"
                    className="w-full h-full px-2.5 py-3.5 bg-transparent"
                    size="small"
                    autoComplete="off"
                    placeholder={item.placeholder}
                    error={item.error || false}
                    helperText={item.error && item.helperText}
                    value={state[item.id] || ""}
                    onChange={(e) =>
                      stateHandler({
                        ...state,
                        [item.id]: item.isUperCase
                          ? e.target.value.toUpperCase()
                          : e.target.value,
                      })
                    }
                  />
                )}
              </span>
            );
          })}
        </div>
        <div className="flex items-end ml-1">
          <Button title="Reset" variant="outlined" style={{ height: "35px", borderRadius: "2px", fontSize: "12px" }} onClick={onReset} />
          <Button title="Filter" variant="contained" style={{ marginLeft: 2, height: "35px", borderRadius: "3px", backgroundColor: "#1890ff", fontSize: "12px" }} onClick={onFilter} />
        </div>
      </div>
    </div >
  );
};

export default FilterComponent;
