import * as React from "react";
import {
  Box,
  SwipeableDrawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Stack,
} from '@mui/material';
import logo from "../../Assets/Images/logo.svg";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { deleteAllCookies } from "../../utils/cookies";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });

  const [open, setOpen] = React.useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (props.open) {
      setState({ left: true });
    } else {
      setState({ left: false });
    }
  }, [props.open, props.setOpen]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    props.setOpen(false);
  };

  async function logout() {
    if (window.confirm("Are you sure you want to logout your session?")) {
      deleteAllCookies();
      localStorage.clear();
      navigate("/");
    }
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 200,  color: "#183A7B", height: "100%"}}
      role="presentation"
    >
      <Stack direction="row" alignItems="center" style={{ padding: '8px 16px' }}>
        <img src={logo} alt="logo" style={{ height: "45px" }} />
      </Stack>
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 200 , fontSize:"12px" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Dashboard" />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List component="div" disablePadding>
            {user?.role === "ORG_ADMIN" && (
              <NavLink
                to="/orders"
                className="no-underline	text-black"

              >
                <ListItemButton sx={{ pl: 2, py:"25px" }}>
                  < LocalShippingIcon />
                  <ListItemText sx={{ pl: 1 }} primary="Orders" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role === "SUPER_ADMIN" && (
              <>
                <NavLink
                  to="/users"
                  className="no-underline	text-black"
                >
                  <ListItemButton sx={{ pl: 2,  py:"25px" }}>
                    <Person2TwoToneIcon />
                    <ListItemText sx={{ pl: 1 }} primary="Users" />
                  </ListItemButton>
                </NavLink>
                <NavLink
                  to="/organisations"
                  className="no-underline	text-black"
                >
                  <ListItemButton sx={{ pl: 2 }}>
                    <CorporateFareTwoToneIcon />
                    <ListItemText sx={{ pl: 1 }} primary="Organisations" />
                  </ListItemButton>
                </NavLink>
              </>
            )}
            <NavLink
              to="/request-logs"
              className="no-underline	text-black"
            >
              <ListItemButton sx={{ pl: 2, py:"25px"  }}>
                <TravelExploreIcon />
                <ListItemText sx={{ pl: 1 }} primary="ONDC Logs" />
              </ListItemButton>
            </NavLink>
            {user?.role === "ORG_ADMIN" && (
              <NavLink
                to="/rsf"
                className="no-underline	text-black"
                sx={{ color: "#183A7B" }}
              >
                <ListItemButton sx={{ pl: 2, py:"25px"  }}>
                  <CurrencyRupeeIcon />
                  <ListItemText sx={{ pl: 1 }} primary="Payments" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role === "ORG_ADMIN" && (
              <NavLink
                to="/issue"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 2 , py:"25px" }}>
                  <SupportAgentIcon />
                  <ListItemText sx={{ pl: 1 }} primary="Issues" />
                </ListItemButton>
              </NavLink>
            )}
            {user?.role === "ORG_ADMIN" && (
              <NavLink
                to="/api-logs"
                className="no-underline	text-black"
              >
                <ListItemButton sx={{ pl: 2, py:"25px"  }}>
                  <SwapVertIcon />
                  <ListItemText sx={{ pl: 1 }} primary="Api Logs" />
                </ListItemButton>
              </NavLink>
            )}
          </List>
        </Collapse>
      </List>
      <List
        style={{ position: "absolute", bottom: "0" }}
        className="w-full flex-row"

      >
        <ListItem key="Log Out" disablePadding>
          <ListItemButton onClick={() => logout()}>
            <LogoutIcon />
            <ListItemText primary="Log Out" className="mx-2" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="left">
        <SwipeableDrawer
          anchor="left"
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
        <Outlet />
      </React.Fragment>
    </div>
  );
}
