import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router'
import cogoToast from "cogo-toast";
import UserTable from "./UserTable";
import { Tab, Tabs } from "@mui/material";
import { getCall } from "../../../Api/axios";
import useNavigation from '../../../hooks/useNavigation'
import useQueryParams from '../../../hooks/useQueryParams'
import { evalQueryString } from '../../../utils/index'
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const superAdminCols = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "isEnabled",
    label: "Status",
  },
];

const orgAdminCols = [
  {
    id: "email",
    label: "Email",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "organisationName",
    label: "Organisation Name",
  },
  {
    id: "isEnabled",
    label: "Status",
  },
  {
    id: "Action",
    label: "Action",
    align: "left",
  },
];

const UserListings = () => {
  const queryParams = useQueryParams()
  const [view, setView] = useState(queryParams.view || 'superAdmin');
  const [orgAdmins, setOrgAdmins] = useState([]);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigation = useNavigation()
  const location = useLocation()

  const handleChange = (event, newValue) => {
    setView(newValue);
  };

  const getSuperAdmins = async () => {
    setLoading(true);
    const url = `/api/v2/users?role=SUPER_ADMIN`;
    try {
      const res = await getCall(url);
      let data = res.message.users;
      data.forEach((d) => {
        d["isEnabled"] = d?.isEnabled ? "Active" : "Inactive";
      });
      setSuperAdmins(data);
      setTotalRecords(res?.total);
      setLoading(false);
    } catch (error) {
      cogoToast.error(error.response.data.error);
      setLoading(false);
    }
  };

  const getOrgAdmins = async () => {
    setLoading(true);
    const url = `/api/v2/users?role=ORG_ADMIN`;
    try {
      const res = await getCall(url);
      let data = res.message.users;
      data.forEach((d) => {
        d["isEnabled"] = d?.isEnabled ? "Active" : "Inactive";
      });
      setOrgAdmins(data);
      setTotalRecords(res.count);
      setLoading(false);
    } catch (error) {
      cogoToast.error(error.response.data.error);
      setLoading(false);
    }
  };

  const isSuperAdmin = useMemo(() => {
    return view === 'superAdmin'
  }, [view])

  useEffect(() => {
    if (isSuperAdmin) getSuperAdmins();
    else getOrgAdmins();
  }, [isSuperAdmin]);
  useEffect(() => {
    navigation.toPathWithQuery(
      `${location.pathname}`,
      evalQueryString(location.search, { view })
    )
  }, [view])

  return (
    <div>
      {loading && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            opacity: 0.5,
            zIndex: 10,
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} />
        </Box>
      )}
      <div className="container mx-auto py-8">
        <div className="flex flex-row justify-between items-center">
          <Tabs
            style={{ marginBottom: 30 }}
            value={view}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab value="superAdmin" label="SUPER ADMINS" />
            <Tab value="orgAdmin" label="ORGANISATION ADMINS" />
          </Tabs>
        </div>

        <UserTable
          view={view}
          columns={isSuperAdmin ? superAdminCols : orgAdminCols}
          data={isSuperAdmin ? superAdmins : orgAdmins}
          isOrgAdmin={isSuperAdmin ? false : true}
          getSuperAdmins={getSuperAdmins}
          getOrgAdmins={getOrgAdmins}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={(val) => setPage(val)}
          handleRowsPerPageChange={(val) => setRowsPerPage(val)}
        />
      </div>
    </div>
  );
};

export default UserListings;
