import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";

export default function Navbar({ sidebarOpen, setSidebarOpen }) {
  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const location = useLocation();

  const getHeading = () => {
    const path = location.pathname;

    if (path === "/orders") {
      return "Orders";
    } else if (path === "/request-logs") {
      return "ONDC Logs";
    } else if (path === "/rsf") {
      return "Payments";
    } else if (path === "/api-logs") {
      return "Api Logs";
    } else if (path === "/users") {
      return "User Listing";
    } else if (path === "/organisations") {
      return "Organisations";
    } else if (path.startsWith("/issue")) {
      return "Issues";
    } 
  };


  return (
    <>
      <AppBar position="sticky" sx={{ background: "#4B92E7" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <div className="ml-4 flex flex-row justify-between items-center">
            <label style={{ color: "#fff" }} className="font-semibold text-xl">
              {getHeading()}
            </label>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
