import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, IconButton } from "@mui/material";
import cogoToast from "cogo-toast"; // For notifications
import { getCall } from "../../../Api/axios";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
    backgroundColor: "#4B92E7",
    color: "#fff",
  },
});

export default function ApiLogsTable(props) {
  const { page, rowsPerPage, totalRecords, handlePageChange, handleRowsPerPageChange } = props;

  const [expandedRows, setExpandedRows] = useState({});
  const [apiLogsChild, setApiLogsChild] = useState({});

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };
  
  const toggleRowExpansion = async (rowId) => {
    const isExpanded = expandedRows[rowId];

    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !isExpanded,
    }));

    if (!isExpanded && !apiLogsChild[rowId]) {
      const url = `/api/v2/dashboard/request-logs/search?requestId=${rowId}`;
      try {
        const res = await getCall(url);
        setApiLogsChild((prevState) => ({
          ...prevState,
          [rowId]: res.message,
        }));
      } catch (error) {
        cogoToast.error(error.message);
      }
    }
  };

  const renderColumn = (row, column) => {
    switch (column.id) {
      case "api":
        return <span>{row.api}</span>;
      case "responseStaus":
        return <span>{row.responseStaus}</span>;
      case "payload":
        return (
          <Box display="flex" alignItems="flex-end">
            <Typography
              variant="body1"
              sx={{
                maxWidth: '250px',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                textOverflow: 'ellipsis',
              }}
            >
              {JSON.stringify(row.payload)}
            </Typography>
            <Typography
              sx={{ cursor: 'pointer', ml: 1 }}
              color="primary"
              variant="body1"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(row.payload))
                cogoToast.success("Copied")
              }}
            >
              Copy
            </Typography>
          </Box>
        );
      case "bppId":
        return <span>{row.bppId}</span>;
      case "orderId":
        return <span>{row.orderId}</span>;
      case "logisticsOrderId":
        return <span>{row.logisticsOrderId}</span>;
      case "txnId":
        return <span>{row.txnId}</span>;
      default:
        return null;
    }
  };

  const renderRespondentActions = (rowId) => {
    const childData = apiLogsChild[rowId];

    if (!childData) {
      return <CircularProgress />;
    }

    return (
      <Table size="small" aria-label="respondent actions">
        <TableHead>
          <TableRow>
            {props.respondentActionColumns.map((actionColumn) => (
              <TableCell key={actionColumn.id} align={actionColumn.align} style={{ fontWeight: "bold" }}>
                {actionColumn.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {childData.map((action, index) => (
            <TableRow key={index}>
              {props.respondentActionColumns.map((actionColumn) => (
                <TableCell key={actionColumn.id} align={actionColumn.align} sx={{ borderBottom: "none" }}>
                  {actionColumn.id === "error"
                    ? action[actionColumn.id] !== null ?
                      <Box display="flex" alignItems="flex-end">
                        <Typography
                          variant="body1"
                          sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {JSON.stringify(action[actionColumn.id])}
                        </Typography>
                        <Typography
                          sx={{ cursor: 'pointer', ml: 1 }}
                          color="primary"
                          variant="body1"
                          onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(action[actionColumn.id]))
                            cogoToast.success("Copied")
                          }}
                        >
                          Copy
                        </Typography>
                      </Box> : "-"
                    : action[actionColumn.id] || "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#4B92E7",
                    color: "#fff",
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: "pointer", width: "100%" }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => toggleRowExpansion(row.requestId)}
                    >
                      {expandedRows[row.requestId] ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  {props.columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {renderColumn(row, column)}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.columns.length + 1}>
                    <Collapse in={expandedRows[row.requestId]} timeout="auto" unmountOnExit>
                      <AccordionDetails>
                        <div style={{ padding: "16px", backgroundColor: "#f1f1f1" }}>
                          {renderRespondentActions(row.requestId)}
                        </div>
                      </AccordionDetails>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
