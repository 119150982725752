import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import AuthActionCard from "../AuthActionCard/AuthActionCard";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { isEmailValid } from "../../../utils/validations";
import { postCall } from "../../../Api/axios";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "2px !important",
    height: "35px !important",
    "& fieldset": {
      borderColor: "d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#1890ff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1890ff",
    },
  },
});

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState('');
  const [disable, setDisable] = useState(false);

  const checkDisabled = () => {
    if (email.trim() === '' || !isEmailValid(email)) return true;
    return false;
  };

  const forgotPassword = async () => {
    const url = `/api/v2/dashboard/forgotPassword`;
    try {
      await postCall(url, { email });
      setError(false);
      setMsg('OTP sent to your email')
    } catch (error) {
      setError(true);
      setMsg(error.response.data.message);
    }
  };

  const handleSubmit = async () => {
    setMsg('')
    await forgotPassword()
  };

  const forgot_password_form = (
    <div className="m-auto w-10/12 md:w-3/4">
      <form>
        <div className="py-1">
          <label
            htmlFor="email"
            className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
          >
            Email
            <span className="text-[#FF0000]"> *</span>
          </label>
          <CssTextField
            required
            size="small"
            name="email"
            type="email"
            placeholder="Enter Email"
            autoComplete="off"
            className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        {msg && <p className={`text-xs ${error ? 'text-red-600' : 'text-green-600'} mt-2`}>{msg}</p>}
        <br />
        <Button
          variant="contained"
          primary
          onClick={handleSubmit}
          disabled={checkDisabled()}
          sx={{backgroundColor: "#1890ff"}}
        >
          Get OTP
        </Button>
      </form>
    </div>
  );
  const navigation_link = (
    <div className="py-2 text-center">
      <p className="text-xs text-[#606161]">Already have an account?</p>
      <NavLink to="/login" className="">
        <p className="text-xs text-[#3d629ad2] hover:text-[#0066ffd2]">Login</p>
      </NavLink>
    </div>
  );
  return (
    <AuthActionCard
      action_form={forgot_password_form}
      navigation_link={navigation_link}
    />
  );
};

export default ForgotPassword;
