import React, { useState } from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@material-ui/core/Toolbar';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { getFullAddress } from "./../../../utils/orders.js";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { CANCELLATION_REASONS } from "../../../constants/cancellationReasons.js";
import OrderTimeline from './OrderFL.jsx';
import { LOGISTICS } from '../../../constants/logistic.js';
import Divider from '@mui/material/Divider';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
    fontSize: "12px",
    padding: "4px"
  },
});

export default function OrderTable(props) {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [fulfillmentId, setFulfillmentId] = useState(null);

  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;

  const navigate = useNavigate();
  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const handleOpenModal = (row, type, fulfillmentId) => {
    setSelectedRow(row);
    setOpenModal(true);
    setSelectedType(type)
    setFulfillmentId(fulfillmentId)
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const renderColumn = (row, column) => {
    switch (column.id) {
      case "logisticOrderId":
        return (
          <>
            <span>{row.logisticOrderId || '-'}</span>
          </>
        );

      case "retailOrderId":
        return (
          <>
            <span>{row.retailOrderId || '-'}</span>
          </>
        );

      case "deliveryAddress":
        return (
          <div>
            <span>{row.deliveryAddress ? getFullAddress(row.deliveryAddress) : "-"}</span>
          </div>
        );

      case "customerName":
        return (
          <>
            <span>{row.customer?.name || "-"}</span> <br />
            <span>{row.customer?.mobile || "-"}</span>
          </>
        );
      case "agentName":
        return (
          <>
            <span>{row.assignedAgent?.name || "-"}</span> <br />
            <span>{row.assignedAgent?.phone || "-"}</span>

          </>
        );

      case "paymentType":
        return (
          <>
            <span>{row.paymentType || '-'}</span>
          </>
        );

      case "deliveryCharge":
        return (
          <>
            <span>₹ {row.deliveryCharge ?? 0}</span>
          </>
        );

      case "status":
        return (
          <div>
            <span>{row.orderStatus || '-'}</span>
          </div>
        );

      case "progressStatus":
        const rtoStatus = row.fulfillment?.find((item) => item.type === "RTO");
        const deliveryStatus = row.fulfillment?.find((item) => item.type === "Delivery");
        return (
          <div>
            <span>{rtoStatus ? rtoStatus.status : deliveryStatus?.status || "-"}</span>
          </div>
        );

      case "createdAt":
        return (
          <>
            <span>{convertDateInStandardFormat(row.createdAt)}</span>
          </>
        );

      case "updatedAt":
        return (
          <>
            <span>{convertDateInStandardFormat(row.updatedAt)}</span>
          </>
        );

      case "bppId":
        const logisticName = LOGISTICS[row.bppId];
        return (
          <span>{logisticName ? logisticName : row.bppId}</span>
        );
      case "cancellationReason":
        const cancellationReason = CANCELLATION_REASONS.find(reasonObj => reasonObj.code === row.cancellationReason);
        return (
          <>
            <span>{cancellationReason ? cancellationReason.reason : "-"}</span>
          </>
        );

      case "cancellationBy":
        return (
          <>
            <span>{row.cancelledBy || "-"}</span>
          </>
        );

      default:
        break;
    }
  }
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#4B92E7",
                    color: "#fff",
                  }}
                  className="font-medium"
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="action-header"
                className="font-medium"
                style={{
                  backgroundColor: "#4B92E7",
                  color: "#fff",
                }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.data.map((row, index) => {
                return (
                  <TableRow
                    style={{ cursor: "pointer", position: "relative" }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => {
                      navigate(`/orders/${row?.logisticOrderId}`);
                    }}
                  >
                    {props.columns.map((column, idx) => {
                      return (
                        <TableCell key={column.id} align={column.align} sx={{ fontSize: "12px", padding: "4px" }}>
                          {renderColumn(row, column)}
                        </TableCell>
                      );
                    })}
                    <TableCell component="th">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {row.fulfillment && row.fulfillment.map((item, index) => (
                          <React.Fragment key={index}>
                            {item.type === "Delivery" && (
                              <Tooltip title="Track Order" style={{ padding: "0px" }}>
                                <IconButton color='primary' onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenModal(row, "Delivery", item.id);
                                }}>
                                  <DeliveryDiningIcon sx={{ color: "green" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {item.type === "RTO" && (
                              <Tooltip title="Track RTO" style={{ padding: "0px" }}>
                                <IconButton color='primary' onClick={(event) => {
                                  event.stopPropagation();
                                  handleOpenModal(row, "RTO", item.id);
                                }}>
                                  <DeliveryDiningIcon style={{ transform: 'scaleX(-1)', color: "orange" }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </TableCell>

                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{selectedType} Tracking</DialogTitle>
        <Divider />
        <DialogContent>
          <OrderTimeline orderId={selectedRow?.logisticOrderId} fulfillmentId={fulfillmentId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Paper >
  );
}
