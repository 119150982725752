export const CANCELLATION_REASONS = [
    {
        code: '001',
        reason: 'Price of one or more items have changed due to which buyer was asked to make additional payment',
    },
    {
        code: '002',
        reason: 'One or more items in the Order not available',
    },
    {
        code: '003',
        reason: 'Product available at lower than order price',
    },
    {
        code: '004',
        reason: 'Store is not accepting order',
    },
    {
        code: '005',
        reason: 'Merchant rejected the order',
    },
    {
        code: '006',
        reason: 'Order / fulfillment not received as per buyer app TAT SLA',
    },
    {
        code: '007',
        reason: 'Order / fulfillment not received as per buyer app TAT SLA',
    },
    {
        code: '008',
        reason: 'Order / fulfillment not ready for pickup',
    },
    {
        code: '009',
        reason: 'Wrong product delivered',
    },
    {
        code: '010',
        reason: 'Buyer wants to modify address / other order details',
    },
    {
        code: '011',
        reason: 'Buyer not found or cannot be contacted',
    },
    {
        code: '012',
        reason: 'Buyer does not want product any more',
    },
    {
        code: '013',
        reason: 'Buyer refused to accept delivery',
    },
    {
        code: '014',
        reason: 'Address not found',
    },
    {
        code: '015',
        reason: 'Buyer not available at location',
    },
    {
        code: '016',
        reason: 'Accident / rain / strike / vehicle issues',
    },
    {
        code: '017',
        reason: 'Order delivery delayed or not possible due to damages, etc.',
    },
    {
        code: '018',
        reason: 'Delivery pin code not serviceable',
    },
    {
        code: '019',
        reason: 'Pickup pin code not serviceable',
    },
    {
        code: '020',
        reason: 'Order lost in transit',
    },
    {
        code: '021',
        reason: 'Packed order not complete',
    },
    {
        code: '999',
        reason: 'Order confirmation failure (Internal error)',
    },
    {
        code: '998',
        reason: 'Order confirmation failure (Seller)',
    },
    {
        code: '997',
        reason: 'Order confirmation failure (Logistics)',
    },
    {
        code: '996',
        reason: 'Order confirmation / completion failure (Logistics Buyer)',
    }
];
