import React from 'react';
import { useState, useEffect } from "react";
import IssueListingTable from "./IssueListingTable"
import { getCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import useCancellablePromise from "../../../Api/cancelRequest";
import FilterComponent from "../../Shared/FilterComponent";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function IssuesListing() {

    const filterFields = [
        {
            id: "issueId",
            title: "",
            placeholder: "Issue Id",
            type: "input",
            variant: "standard",
        },
        {
            id: "orderId",
            title: "",
            placeholder: "Order Id",
            type: "input",
            variant: "standard",
        },
        {
            id: "status",
            title: "",
            placeholder: "Status",
            type: "select",
            variant: "standard",
            options: [
                { value: "OPEN", label: "Open" },
                { value: "CLOSE", label: "Close" }
            ],
        },
        {
            id: "issueType",
            title: "",
            placeholder: "Issue Type",
            type: "select",
            variant: "standard",
            options: [
                { label: "Issue", value: "ISSUE" },
                { label: "Grievance", value: "GRIEVANCE" },
                { label: "Dispute", value: "DISPUTE" },
            ],
        },
    ];

    const columns = [
        {
            id: "issueId",
            label: "Issue ID",
            minWidth: 80,
            align: "center",
        },
        {
            id: "orderId",
            label: "Order ID",
            minWidth: 80,
            align: "center",
        },
        {
            id: "subject",
            label: "Subject",
            minWidth: 80,
            align: "center",
            format: (value) => value.join(", "),
        },
        {
            id: "status",
            label: "Status",
            minWidth: 80,
            align: "center",
            format: (value) => value.toUpperCase(),
        },
        {
            id: "subCategory",
            label: "Sub Category",
            minWidth: 80,
            align: "center",
        },
        {
            id: "category",
            label: "Category",
            minWidth: 80,
            align: "center",
        },
        {
            id: "issueType",
            label: "Issue Type",
            minWidth: 80,
            align: "center",
        },
        {
            id: "createdAt",
            label: "Created At",
            minWidth: 80,
            align: "center",
            format: (value) => new Date(value).toLocaleDateString(),
        },
        {
            id: "updatedAt",
            label: "Updated At",
            minWidth: 80,
            align: "center",
            format: (value) => new Date(value).toLocaleDateString(),
        },
    ];

    const respondentActionColumns = [
        {
            id: "action",
            label: "Action",
            minWidth: 180,
            align: "center",
        },
        {
            id: "status",
            label: "status",
            minWidth: 180,
            align: "center",
        },
        {
            id: "short_desc",
            label: "Description",
            minWidth: 180,
            align: "center",
        },
        {
            id: "updated_by",
            label: "Updated By",
            minWidth: 180,
            align: "center",
        },
        {
            id: "updated_at",
            label: "Updated At",
            minWidth: 180,
            align: "center",
            format: (value) => new Date(value).toLocaleDateString(),
        },
    ];

    const { cancellablePromise } = useCancellablePromise();
    const params = useParams();
    const [issue, setIssue] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [columnList, setColumnList] = useState(columns);
    const [loading, setLoading] = useState(true);
    const [onRefresh, setOnRefresh] = useState(false);
    const [filters, setFilters] = useState({
        orderId: params?.orderId || "",
        issueId: "",
        status: "",
        issueType: "",
    });

    const getIssue = async () => {
        setLoading(true);
        let filterParams = [];
        let url = "";
        if (filters.orderId.trim() !== "") {
            filterParams.push(`orderId=${encodeURIComponent(filters.orderId)}`);
        }
        if (filters.issueId.trim() !== "") {
            filterParams.push(`issueId=${encodeURIComponent(filters.issueId)}`);
        }
        if (filters.status.trim() !== "") {
            filterParams.push(`status=${encodeURIComponent(filters.status)}`);
        }
        if (filters.issueType.trim() !== "") {
            filterParams.push(`issueType=${encodeURIComponent(filters.issueType)}`);
        }
        if (filterParams) {
            const queryString = filterParams.join("&");
            url = `/api/v2/dashboard/issue?limit=${rowsPerPage}&page=${page}&${queryString}`;
        } else {
            url = `/api/v2/dashboard/issue?limit=${rowsPerPage}&page=${page}`;
        }
        try {
            const res = await getCall(url);
            let data = res.response.issues.map(issue => ({
                ...issue,
                respondentAction: issue.actions || [],
            }));
            setIssue(data);
            setTotalRecords(res.response.total);
            setOnRefresh(false)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            cogoToast.error(error.message);
        }
    };

    useEffect(() => {
        getIssue()
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (params.orderId) {
            onFilter();
        }
    }, [params.orderId]);

    useEffect(() => {
        if (onRefresh) {
            getIssue()
        }
    }, [onRefresh]);

    const onReset = () => {
        setFilters({ orderId: "", issueId: "", status: "", issueType: "" });
        setPage(0);
        setOnRefresh(true);
    };

    const onFilter = async () => {
        getIssue()
    };
    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}
            <div className="container mx-auto py-8">
                <FilterComponent
                    fields={filterFields}
                    state={filters}
                    stateHandler={setFilters}
                    onReset={onReset}
                    onFilter={onFilter}
                />
                <IssueListingTable
                    columns={columnList}
                    respondentActionColumns={respondentActionColumns}
                    data={issue}
                    totalRecords={totalRecords}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(val) => setPage(val)}
                    handleRowsPerPageChange={(val) => setRowsPerPage(val)}
                    onRefresh={setOnRefresh}
                />
            </div>
        </>
    );
}
