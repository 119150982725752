import React, { useState, useEffect } from "react";
import OrderTable from "../Order/OrderTable";
import { getCall } from "../../../Api/axios";
import { useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import FilterComponent from "../../Shared/FilterComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const filterFields = [
  {
    id: "orderId",
    title: "",
    placeholder: "Logistic Order Id",
    type: "input",
    variant: "standard",
  },
  {
    id: "retailOrderId",
    title: "",
    placeholder: "Retail Order Id",
    type: "input",
    variant: "standard",
  },
  {
    id: "txId",
    title: "",
    placeholder: "Transaction Id",
    type: "input",
    variant: "standard",
  },
];

const columns = [
  { id: "logisticOrderId", label: "Logistic Order Id", minWidth: 50, align: "left" },
  { id: "retailOrderId", label: "Retail Order Id", minWidth: 50, align: "left" },
  { id: "status", label: "Status", minWidth: 50, align: "left" },
  { id: "progressStatus", label: "Delivery Status", minWidth: 50, align: "left" },
  { id: "agentName", label: "Agent", minWidth: 50, align: "left" },
  {
    id: "deliveryCharge",
    label: "Delivery Fee",
    minWidth: 50,
    align: "left",
    format: (value) => `$${value.toFixed(2)}`
  },
  { id: "customerName", label: "Customer", minWidth: 50, align: "left" },
  { id: "bppId", label: "Logistic ID", minWidth: 50, align: "left" },
  { id: "cancellationReason", label: "Cancellation Reason", minWidth: 50, align: "left" },
  { id: "cancellationBy", label: "Cancelled By", minWidth: 50, align: "left" },
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 50,
    format: (value) => new Date(value).toLocaleString("en-US"),
    align: "left"
  },
  {
    id: "updatedAt",
    label: "Updated At",
    minWidth: 50,
    format: (value) => new Date(value).toLocaleString("en-US"),
    align: "left"
  },
];

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    orderId: "",
    retailOrderId: "",
    txId: "",
  });
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user.isSystemGeneratedPassword) {
      navigate("/reset-password");
    } else {
      if (user.role === "ORG_ADMIN") {
        navigate("/orders");
      } else if (user.role === 'SUPER_ADMIN') {
        navigate("/users");
      }
    }
  }, []);

  const getOrders = () => {
    setLoading(true);
    const url = `/api/v2/dashboard/orders?limit=${rowsPerPage}&page=${page}`;
    getCall(url)
      .then((resp) => {
        setOrders(resp.message);
        setTotalRecords(resp.total);
        setLoading(false);
      })
      .catch((error) => {
        cogoToast.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, [page, rowsPerPage]);

  const onReset = () => {
    setFilters({ orderId: "", retailOrderId: "", txId: "" });
    getOrders();
  };

  const onFilter = async () => {
    const filterParams = [];
    if (filters.orderId.trim() !== "") {
      filterParams.push(`orderId=${encodeURIComponent(filters.orderId)}`);
    }
    if (filters.retailOrderId.trim() !== "") {
      filterParams.push(`retailOrderId=${encodeURIComponent(filters.retailOrderId)}`);
    }
    if (filters.txId.trim() !== "") {
      filterParams.push(`txId=${encodeURIComponent(filters.txId)}`);
    }

    const queryString = filterParams.join("&");
    const url = `/api/v2/dashboard/orders?limit=${rowsPerPage}&page=${page}&${queryString}`;
    setLoading(true);
    getCall(url)
      .then((resp) => {
        setOrders(resp.message);
        setTotalRecords(resp.total);
        setLoading(false);
      })
      .catch((error) => {
        cogoToast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            opacity: 0.5,
            zIndex: 10,
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} />
        </Box>
      )}
      <div className="container mx-auto py-8">
        <FilterComponent
          fields={filterFields}
          state={filters}
          stateHandler={setFilters}
          onReset={onReset}
          onFilter={onFilter}
        />
        <OrderTable
          columns={columns}
          data={orders}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={(val) => setPage(val)}
          handleRowsPerPageChange={(val) => setRowsPerPage(val)}
        />
      </div>
    </>
  );
}
