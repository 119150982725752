import React, { useEffect, useState } from "react";
import Button from "../../Shared/Button";
import AuthActionCard from "../AuthActionCard/AuthActionCard";
import { NavLink, useNavigate } from "react-router-dom";
import ErrorMessage from "../../Shared/ErrorMessage";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { AddCookie } from "../../../utils/cookies";
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { getValueFromCookie } from "../../../utils/cookies";


const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "2px !important",
    height: "35px !important",
    "& fieldset": {
      borderColor: "d9d9d9",
    },
    "&:hover fieldset": {
      borderColor: "#1890ff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1890ff",
    },
  },
});

export default function Login() {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [signInUsingEmailAndPasswordloading] = useState(false);
  const [inlineError, setInlineError] = useState({
    email_error: "",
    password_error: "",
  });

  // use this function to check the email
  function checkEmail() {
    if (!login.email) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: "Email cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkPassword() {
    if (!login.password) {
      setInlineError((inlineError) => ({
        ...inlineError,
        password_error: "Password cannot be empty",
      }));
      return false;
    }

    return true;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    const url = "/api/v2/dashboard/login";
    try {
      const res = await postCall(url, login);
      handleRedirect(res.message.token, res.message.user);
    } catch (error) {
      if (error.response) {
        cogoToast.error(error.response.data.message);
      } else {
        cogoToast.error(error.message);
      }
    }
  };


  function handleRedirect(token, user) {
    const { _id } = user;
    AddCookie("token", token);
    localStorage.setItem("user_id", _id);
    localStorage.setItem("user", JSON.stringify(user));
    navigate("/orders");
  }

  useEffect(() => {
    if (getValueFromCookie("token")) {
      navigate("/orders");
    }
  }, []);


  const loginForm = (
    <div className="m-auto w-10/12 md:w-3/4">
      <div className="py-1">
        <label
          htmlFor="email"
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Email
          <span className="text-[#FF0000]"> *</span>
        </label>
        <CssTextField
          id={
            inlineError.email_error
              ? "outlined-error"
              : "demo-helper-text-aligned"
          }
          name="email"
          type="email"
          placeholder="Enter Email"
          autoComplete="off"
          className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent !border-black"
          onChange={(event) => {
            setLogin({ ...login, email: event.target.value });
            setInlineError((inlineError) => ({
              ...inlineError,
              email_error: "",
            }));
          }}
          size="small"
          onBlur={checkEmail}
          error={inlineError.email_error ? true : false}
          // helperText={inlineError.email_error && inlineError.email_error}
          required
        />
      </div>
      {inlineError.email_error && (
        <ErrorMessage>{inlineError.email_error}</ErrorMessage>
      )}
      <div className="py-1">
        <label
          htmlFor="password"
          className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block"
        >
          Password
          <span className="text-[#FF0000]"> *</span>
        </label>
        <CssTextField
          id={
            inlineError.password_error
              ? "outlined-error"
              : "demo-helper-text-aligned"
          }
          name="password"
          type="password"
          placeholder="Enter Password"
          autoComplete="off"
          className="w-full h-full px-2.5 py-3.5 text-[#606161] bg-transparent"
          onChange={(event) => {
            setLogin({ ...login, password: event.target.value });
            setInlineError((inlineError) => ({
              ...inlineError,
              password_error: "",
            }));
          }}
          size="small"
          onBlur={checkPassword}
          error={inlineError.password_error ? true : false}
          style={{ borderRadius: "10px" }}
          required
        />
      </div>
      {inlineError.password_error && (
        <ErrorMessage>{inlineError.password_error}</ErrorMessage>
      )}
      <div className="py-3 pt-6  text-center flex flex-row justify-center">
        <Button
          isloading={signInUsingEmailAndPasswordloading ? 1 : 0}
          disabled={
            signInUsingEmailAndPasswordloading ||
            login.email === "" ||
            login.password === ""
          }
          variant="contained"
          title="Login"
          className="!w-40 !capitalize !py-2"
          onClick={(e) => handleLogin(e)}
          sx={{backgroundColor: "#1890ff"}}
        />
      </div>
    </div>
  );
  const navigation_link = (
    <div className="py-2 text-center">
      <NavLink to="/forgot-password" className="">
        <p className="text-xs text-[#3d629ad2] hover:text-[#0066ffd2]">
          Forgot password
        </p>
      </NavLink>
    </div>
  );
  return (
    <AuthActionCard action_form={loginForm} navigation_link={navigation_link} />
  );
}
