import React, { useState, useEffect } from "react";
import { Button, CircularProgress, TextField, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { IGM_CODES } from "../../../constants/IGMCodes";

const formControlStyles = {
    height: "35px !important",
    "& .MuiInputLabel-root": {
        fontSize: "12px !important",
        transform: "translate(14px, 8px) scale(1)",
        "&.Mui-focused": {
            transform: "translate(14px, -6px) scale(0.75)",
        },
        "&.MuiFormLabel-filled": {
            transform: "translate(14px, -6px) scale(0.75)",
        },
    },
    "& .MuiSelect-select": {
        fontSize: "12px !important",
        padding: "8px 14px",
        display: "flex",
        height: "35px !important",
        alignItems: "center",
    },
    "& .MuiInputBase-input": {
        fontSize: "12px !important",
        padding: "0px 10px",
        lineHeight: "normal",
        height: "35px",
        display: "flex",
        alignItems: "center",
    },
};

const issueFields = [
    {
        id: "subject",
        title: "Subject",
        placeholder: "Enter the subject",
        type: "input",
        required: true,
    },
    {
        id: "issueType",
        title: "Issue Type",
        options: [
            { key: "Issue", value: "ISSUE" },
            { key: "Grievance", value: "GRIEVANCE" },
            { key: "Dispute", value: "DISPUTE" },
        ],
        type: "select",
        required: true,
    },
    {
        id: "description",
        title: "Description",
        placeholder: "Describe your issue",
        type: "textarea",
        required: true,
    },
    {
        id: "category",
        title: "Category",
        options: [
            { key: "ORDER", value: "ORDER" },
            { key: "ITEM", value: "ITEM" },
            { key: "FULFILLMENT", value: "FULFILLMENT" },
            { key: "AGENT", value: "AGENT" },
            { key: "PAYMENT", value: "PAYMENT" },
        ],
        type: "select",
        required: true,
    },
];

const RaiseIssue = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [issueDetails, setIssueDetails] = useState({
        logisticsOrderId: params.id,  // Assign orderId as logisticsOrderId
        subject: "",
        description: "",
        category: "",
        subCategory: "",
        issueType: "",
        images: [],  // Empty array for images, you can implement image upload later
    });
    const [subCategories, setSubCategories] = useState([]);
    const [apiInProgress, setApiInProgress] = useState(false);

    const handleInputChange = (field, value) => {
        setIssueDetails((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    // Handle filtering subcategories based on category selection
    useEffect(() => {
        if (issueDetails.category) {
            const filteredSubCategories = IGM_CODES.filter(
                (code) => code.Category === issueDetails.category
            );
            setSubCategories(filteredSubCategories);
        } else {
            setSubCategories([]);
        }
    }, [issueDetails.category]);

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        issueFields.forEach((field) => {
            if (field.required && !issueDetails[field.id]) {
                isValid = false;
                newErrors[field.id] = `${field.title} is required`;
            }
        });
        return isValid;
    };

    const onSubmitIssue = () => {
        if (validateForm()) {
            const url = `/api/v2/dashboard/issue`;
            setApiInProgress(true);
            postCall(url, issueDetails)
                .then((resp) => {
                    cogoToast.success("Issue raised successfully");
                    setApiInProgress(false);
                    navigate(`/orders/${params.id}`);
                })
                .catch((error) => {
                    setApiInProgress(false);
                    cogoToast.error(error.message);
                });
        } else {
            cogoToast.error("Please fill in all required fields");
        }
    };

    const renderFormFields = () => {
        return issueFields.map((field) => {
            switch (field.type) {
                case "select":
                    return (
                        <div key={field.id} style={{ marginBottom: "1.5rem" }}>
                            <FormControl fullWidth required={field.required} sx={formControlStyles} disabled={apiInProgress}>
                                <InputLabel>{field.title}</InputLabel>
                                <Select
                                    value={issueDetails[field.id]}
                                    onChange={(e) => handleInputChange(field.id, e.target.value)}
                                    label={field.title}
                                >
                                    {field.options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.key}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    );
                case "textarea":
                    return (
                        <div key={field.id} style={{ marginBottom: "1.5rem" }}>
                            <TextField
                                disabled={apiInProgress}
                                label={field.title}
                                multiline
                                rows={3}
                                fullWidth
                                value={issueDetails[field.id]}
                                onChange={(e) => handleInputChange(field.id, e.target.value)}
                                placeholder={field.placeholder}
                                required={field.required}
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        fontSize: "12px !important",  // Adjust the placeholder font size

                                    },
                                    "& .MuiInputBase-input": {
                                        fontSize: "12px !important",  // Text font size in input
                                    },
                                }}
                            />
                        </div>
                    );
                case "input":
                    return (
                        <div key={field.id} style={{ marginBottom: "1.5rem" }}>
                            <TextField
                                label={field.title}
                                fullWidth
                                value={issueDetails[field.id]}
                                onChange={(e) => handleInputChange(field.id, e.target.value)}
                                placeholder={field.placeholder}
                                required={field.required}
                                sx={formControlStyles}
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <div>
            <div className="container bg-slate-50 mx-auto my-8">
                <div>
                    <div
                        className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
                        style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
                    >
                        <div className="m-auto w-10/12 md:w-3/4 h-max">
                            <BackNavigationButton onClick={() => navigate(`/orders/${params.id}`)} />
                            <p className="text-lg font-semibold mb-4">Raise an Issue</p>
                            {renderFormFields()}
                            <div style={{ marginBottom: "1.5rem" }}>
                                <FormControl fullWidth sx={formControlStyles} disabled={apiInProgress}>
                                    <InputLabel>
                                        Sub-category
                                    </InputLabel>
                                    <Select
                                        value={issueDetails.subCategory}
                                        onChange={(e) => handleInputChange("subCategory", e.target.value)}
                                        label="Sub-category"
                                    >
                                        {subCategories.map((sub) => (
                                            <MenuItem
                                                key={sub.SubCategory}
                                                value={sub.SubCategory}
                                            >
                                                {sub.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex mt-6">
                                <Button
                                    disabled={apiInProgress}
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={onSubmitIssue}
                                    sx={{ backgroundColor: "#1890ff" }}
                                >
                                    {apiInProgress ?  <CircularProgress size={16} /> : 'Submit Issue'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RaiseIssue;
