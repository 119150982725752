import { Button } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postCall } from "../../../Api/axios";
import useForm from "../../../hooks/useForm";
import RenderInput from "../../../utils/RenderInput";
import {
  validatePasswordComplexity,
} from "../../../utils/formatting/string";
import { deleteAllCookies } from "../../../utils/cookies";
import cogoToast from "cogo-toast";

const passwordFields = [
  {
    id: "password_1",
    title: "Password",
    placeholder: "Password",
    type: "input",
    password: true,
    required: true,
  },
  {
    id: "password_2",
    title: "Confirm Password",
    placeholder: "Confirm Password",
    type: "input",
    password: true,
    required: true,
  },
];


const ResetPassword = () => {
  const navigate = useNavigate();
  const password = { password_1: "", password_2: "" };
  const user = JSON.parse(localStorage.getItem("user"));

  const {
    formValues: form1Values,
    setFormValues: setForm1Values,
    errors: form1Errors,
    setErrors: setForm1Errors,
  } = useForm({ ...password });

  const [form1Submitted, setForm1Submited] = useState(false);

  const validateForm1 = () => {
    const formErrors = {};
    formErrors.password_1 =
      form1Values.password_1 === ""
        ? "Password is required"
        : !validatePasswordComplexity(form1Values.password_1)
          ? `
    Password should have minimum 8 characters,
     at least one upper case character,
     at least one lower case character,
     at least one special character
     and at least one digit`
          : "";
    formErrors.password_2 =
      form1Values.password_2 === ""
        ? "Confirm Password is required"
        : form1Values.password_1 !== form1Values.password_2
          ? "Passwords don't match"
          : "";
    setForm1Errors({
      ...formErrors,
    });
    return !Object.values(formErrors).some((val) => val !== "");
  };

  useEffect(() => {
    if (user.isSystemGeneratedPassword) {
      navigate("/reset-password");
    } else {
      if (user.role === "ORG_ADMIN") {
        navigate("/orders");
      } else if (user.role === 'SUPER_ADMIN') navigate("/users");
    }
  }, []);

  const handleSetPasswordReq = async () => {
    const url = `/api/v2/dashboard/resetPassword/${user._id}`;
    try {
      const res = await postCall(url, { password: form1Values.password_1, confirmPassword: form1Values.password_2 });
      cogoToast.success("Password set successfully!");
      deleteAllCookies();
      localStorage.clear();
      navigate("/");
    } catch (error) {
      cogoToast.error(error.message);
    }
  };

  const handleSubmit = () => {
    setForm1Submited(true);
    if (validateForm1()) handleSetPasswordReq();
  };

  useEffect(() => {
    if (!form1Submitted) return;
    validateForm1();
  }, [form1Values]);


  const renderSetPasswordFields = () => {
    return passwordFields.map((item) => (
      <RenderInput
        item={{
          ...item,
          error: form1Errors?.[item.id] ? true : false,
          helperText: form1Errors?.[item.id] || "",
        }}
        state={form1Values}
        stateHandler={setForm1Values}
      />
    ));
  };

  return (
    <>
      <div className="mx-auto !p-5 h-screen min-vh-100 overflow-auto bg-[#f0f0f0]">
        <div className="h-full flex fex-row items-center justify-center">
          <div
            className="flex w-full md:w-2/4 bg-white px-4 py-4 rounded-md shadow-xl h-max scrollbar-hidden"
            style={{ minHeight: "85%", maxHeight: "100%", overflow: "auto" }}
          >
            <div className="m-auto w-10/12 md:w-3/4 h-max">
              <form>
                <p className="text-2xl font-semibold mb-4 text-center">
                  Set Password
                </p>
                <div>
                  {renderSetPasswordFields()}
                </div>
                <div className="flex mt-6">
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{backgroundColor: "#1890ff"}}
                  >
                    Continue
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
