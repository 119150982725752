import React from 'react';
import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (user.isSystemGeneratedPassword) {
            navigate("/reset-password");
        }
        else {
            if (user?.role == "ORG_ADMIN") {
                navigate("/orders");
            } else if (user?.role === "SUPER_ADMIN") {
                navigate("/users");
            }
        }
    }, [])

    const value = useMemo(
        () => ({
            user,
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
