import React from "react";
import { useState, useEffect } from "react";
import OrganisationListingTable from "./OrganisationListingTable";
import { getCall } from "../../../Api/axios";
import { useTheme } from '@mui/material/styles';
import cogoToast from "cogo-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const columns = [
    {
        id: "_id",
        label: "Organisation Id",
        minWidth: 180,
        format: (value) => value.toLocaleString("en-US"),
        align: "center",
    },
    {
        id: "name",
        label: "Organisation Name",
        minWidth: 180,
        format: (value) => value.toLocaleString("en-US"),
        align: "center",
    },
    {
        id: "createdAt",
        label: "Created On",
        minWidth: 180,
        format: (value) => value.toLocaleString("en-US"),
        align: "center",
    },
    {
        id: "updatedAt",
        label: "Modified On",
        minWidth: 180,
        format: (value) => value.toLocaleString("en-US"),
        align: "center",
    }
];

export default function OrganisationListing() {
    const theme = useTheme();
    const [organisations, setOrganisations] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [columnList, setColumnList] = useState(columns);
    const [loading, setLoading] = useState(true);

    const getOrgAdmins = async () => {
        setLoading(true);
        const url = `/api/v2/orgs`;
        try {
            const res = await getCall(url);
            let data = res?.response?.orgs;
            setOrganisations(data);
            setTotalRecords(res?.total)
            setLoading(false);
        } catch (error) {
            cogoToast.error(error.response.data.error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrgAdmins()
    }, []);

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}
            <div className="container mx-auto py-8">
                <OrganisationListingTable
                    columns={columnList}
                    data={organisations}
                    totalRecords={totalRecords}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(val) => setPage(val)}
                    handleRowsPerPageChange={(val) => setRowsPerPage(val)}
                />
            </div>
        </>
    );
}
