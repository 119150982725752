import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Components/Auth/Login/Login";
import ForgotPassword from "../Components/Auth/ForgotPassword/ForgotPassword";
import PageNotFound from "../Components/PageNotFound/PageNotFound";
import UserListings from "../Components/Application/UserListings/UserListings";
import Order from "../Components/Application/Order/Order";
import OrderDetails from "../Components/Application/Order/OrderDetails";
import RequestLogs from "../Components/Application/RequestLogs/RequestLogs";
import RSFListing from "../Components/Application/RSFListing/RSFListing";
import PrivateRoute from "./PrivateRoutes";
import ResetPassword from "../Components/Auth/ResetPassword/ResetPassword";
import OrganisationListing from "../Components/Application/OrganisationListing/OrganisationListing";
import IssueListing from "../Components/Application/Issue/IssueListing";
import RaiseIssue from "../Components/Application/Issue/RaiseIssue";
import ApiLogsListing from "../Components/Application/ApiLogs/ApiLogs";
import OrderTimeline from "../Components/Application/Order/OrderFL";
export default function LspRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path={"/orders"}
          element={
            <PrivateRoute>
              <Order />
            </PrivateRoute>
          }
        />
        <Route
          path={"/orders/:id"}
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <UserListings />
            </PrivateRoute>
          }
        />
        <Route
          path="/organisations"
          element={
            <PrivateRoute>
              <OrganisationListing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/orders/:id"
          element={
            <PrivateRoute>
              <OrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/request-logs"
          element={
            <PrivateRoute>
              <RequestLogs />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/rsf"
          element={
            <PrivateRoute>
              <RSFListing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/api-logs"
          element={
            <PrivateRoute>
              <ApiLogsListing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/issue/:orderId?"
          element={
            <PrivateRoute>
              <IssueListing />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/raise-issue/:id"
          element={
            <PrivateRoute>
              <RaiseIssue />
            </PrivateRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PrivateRoute>
              <ResetPassword />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
