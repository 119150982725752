import React from 'react';
import LspRoutes from "./Router/Router";

function App() {
  return (
    <div className='App'>
      <LspRoutes />
    </div>
  );
}

export default App;
