import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import cogoToast from "cogo-toast"; // Assuming you are using this for notifications
import { Button, IconButton, Popover, Box, Stack, RadioGroup, FormControlLabel, Radio, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { postCall } from "../../../Api/axios";
import { IGM_CODES } from "../../../constants/IGMCodes";

const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
    backgroundColor: "#4B92E7",
    color: "#fff",
  },
});

export default function IssueListingTable(props) {
  const { page, rowsPerPage, totalRecords, handlePageChange, handleRowsPerPageChange, onRefresh } = props;

  const [expandedRows, setExpandedRows] = useState({});

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const renderColumn = (row, column) => {
    switch (column.id) {
      case "issueId":
        return <span>{row.issueId}</span>;
      case "orderId":
        return <span>{row.orderId}</span>;
      case "subject":
        return <span>{row.subject}</span>;
      case "status":
        return <span>{row.status}</span>;
      case "subCategory":
        const subCategoryObj = IGM_CODES.find(subCat => subCat.SubCategory === row.subCategory);
        return (
          <>
            <span>{subCategoryObj ? subCategoryObj.Name : "-"}</span>
          </>
        );
      case "category":
        return <span>{row.category}</span>;
      case "issueType":
        return <span>{row.issueType}</span>;
      case "createdAt":
        return <span>{new Date(row.createdAt).toLocaleDateString()}</span>;
      case "updatedAt":
        return <span>{new Date(row.updatedAt).toLocaleDateString()}</span>;
      default:
        return null;
    }
  };

  const renderRespondentActions = (actions) => {
    if (!actions || actions.length === 0) {
      return <span>No actions available</span>;
    }

    return (
      <Table size="small" aria-label="respondent actions">
        <TableHead>
          <TableRow>
            {props.respondentActionColumns.map((actionColumn) => (
              <TableCell key={actionColumn.id} align={actionColumn.align} style={{ fontWeight: 'bold' }}>
                {actionColumn.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action, index) => (
            <TableRow key={index}>
              {props.respondentActionColumns.map((actionColumn) => (
                <TableCell key={actionColumn.id} align={actionColumn.align}>
                  {actionColumn.id === 'action' ? (
                    action.action.respondent_action ? "Respondent" : "Complainant"
                  ) : actionColumn.id === 'status' ? (
                    action.action.respondent_action || action.action.complainant_action || "-"
                  ) : actionColumn.id === 'short_desc' ? (
                    action.action.short_desc || "-"
                  ) : actionColumn.id === 'updated_by' ? (
                    action.action.updated_by?.person?.name || "-"
                  ) : actionColumn.id === 'updated_at' ? (
                    action.updatedAt || "-"
                  ) : action[actionColumn.id] || "-"
                  }
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const ActionMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [likeDislike, setLikeDislike] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [description, setDescription] = useState("");

    const handleClick = (e) => {
      setSelectedAction(null);
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setSelectedAction(null);
      setLikeDislike(null);
      setDescription("");
      setAnchorEl(null);
    };

    const handleLikeDislikeSelection = (option) => {
      setLikeDislike(option);
    };

    const handleActionSubmit = () => {
      let data = {};

      if (selectedAction === "close" && likeDislike) {
        data = {
          issueId: row.issueId,
          rating: likeDislike === "like" ? "THUMBS-UP" : "THUMBS-DOWN",
        };
      } else if (selectedAction === "escalate" && description.trim()) {
        data = {
          issueId: row.issueId,
          description: description,
        };
      } else {
        cogoToast.error("Please complete the required fields.");
        return;
      }
      let apiUrl = `/api/v2/dashboard/issue/${selectedAction}`;
      postCall(apiUrl, data)
        .then((resp) => {
          cogoToast.success(`Issue ${selectedAction}d successfully!`);
          handleClose();
          onRefresh(true);
        })
        .catch((error) => {
          cogoToast.error(error.response?.data?.error || error.message);
        });
    };

    const isSubmitEnabled = () => {
      if (selectedAction === "close") {
        return Boolean(likeDislike);
      }
      if (selectedAction === "escalate") {
        return description.trim().length > 0;
      }
      return false;
    };
    const isClosed = row.status === "CLOSE";
    return (
      <>
        <Tooltip title="Update status">
          <IconButton color="primary" onClick={handleClick} disabled={isClosed}>
            <EditOutlined sx={{ color: "#1890ff" }} />
          </IconButton>
        </Tooltip>
        <Popover
          id="action-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ width: "400px", p: 2 }}>
            <Stack direction="column" spacing={2}>
              {/* Conditionally render Close and Escalate buttons based on selectedAction */}
              {!selectedAction && (
                <>
                  <Button variant="outlined" onClick={() => setSelectedAction("close")} >
                    Close
                  </Button>
                  <Button variant="outlined" onClick={() => setSelectedAction("escalate")}>
                    Escalate
                  </Button>
                </>
              )}

              {/* If 'Close' is selected, show Like/Dislike radio buttons */}
              {selectedAction === "close" && (
                <Box sx={{ mt: 2 }}>
                  <Box display="flex" justifyContent="center" gap={2}>
                    <IconButton
                      onClick={() => handleLikeDislikeSelection("like")}
                      sx={{
                        backgroundColor: likeDislike === "like" ? "#0070ad" : "transparent",
                        color: likeDislike === "like" ? "#fff" : "#000",
                        border: "1px solid #0070ad",
                        "&:hover": {
                          backgroundColor: "#0070ad",
                          color: "#fff",
                        },
                      }}
                    >
                      <ThumbUpIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleLikeDislikeSelection("dislike")}
                      sx={{
                        backgroundColor: likeDislike === "dislike" ? "#d32f2f" : "transparent",
                        color: likeDislike === "dislike" ? "#fff" : "#000",
                        border: "1px solid #d32f2f",
                        "&:hover": {
                          backgroundColor: "#d32f2f",
                          color: "#fff",
                        },
                      }}
                    >
                      <ThumbDownIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}

              {/* If 'Escalate' is selected, show a description input */}
              {selectedAction === "escalate" && (
                <TextField
                  label="Reason for escalation"
                  multiline
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                />
              )}

              {/* Display the action buttons */}
              {selectedAction && (
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <Button size="small" variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleActionSubmit}
                    disabled={!isSubmitEnabled()}
                    sx={{ backgroundColor: "#1890ff" }}
                  >
                    Submit
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>
        </Popover>
      </>
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#4B92E7",
                    color: "#fff",
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="action-header"
                className="font-medium"
                style={{
                  backgroundColor: "#4B92E7",
                  color: "#fff",
                }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: "pointer", width: "100%" }}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => toggleRowExpansion(row.issueId)}
                    >
                      {expandedRows[row.issueId] ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  {props.columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {renderColumn(row, column)}
                    </TableCell>
                  ))}
                  <TableCell component="th" scope="row">
                    <ActionMenu row={row} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.columns.length + 1}>
                    <Collapse in={expandedRows[row.issueId]} timeout="auto" unmountOnExit>
                      <AccordionDetails>
                        <div style={{ padding: "16px", backgroundColor: "#f1f1f1" }}>
                          {renderRespondentActions(row.respondentAction)}
                        </div>
                      </AccordionDetails>
                    </Collapse>
                  </TableCell>

                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
