import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@mui/material";
import { getCall } from "../../../Api/axios";
import {
  getShortAddress,
} from "./../../../utils/orders.js";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import cogoToast from "cogo-toast";

const OrderDetails = () => {
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const navigate = useNavigate();

  const getOrder = async () => {
    setLoading(true);
    const url = `/api/v2/dashboard/order-details?orderId=${params?.id}`;
    getCall(url).then((resp) => {
      setOrder(resp.message[0]);
      setLoading(false);
    }).catch((error) => {
      cogoToast.error(error.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (params.id) getOrder();
  }, [params]);

  const cardClass = `border-2 border-gray-200 rounded-lg p-2 bg-slate-50`;

  const total_order_price = order?.quotePriceValue || 0;
  const price_breakup = order?.breakdown;
  let delivery_charges = 0;
  let total_base_cost = 0;
  let total_tax_cost = 0;
  if (price_breakup) {
    const delivery_charges_object = price_breakup?.filter(
      (b) => b.titleType == "delivery"
    );

    const tax_charges_object = price_breakup?.filter(
      (b) => b.titleType == "tax"
    );

    if (delivery_charges_object && delivery_charges_object?.length > 0) {
      delivery_charges = delivery_charges_object[0]?.priceValue;
    }

    if (tax_charges_object && tax_charges_object?.length > 0) {
      total_tax_cost = tax_charges_object[0]?.priceValue;
    }

    if (order.linkedOrderItems && order.linkedOrderItems?.length > 0) {
      order.linkedOrderItems.forEach((o) => {
        total_base_cost += parseFloat(o?.priceValue);
      });
    }
  }
  const delivery_info = order?.endLocationAddress;
  const rtoStatus = order?.fulfillment?.find((item) => item.type === "RTO");
  const deliveryStatus = order?.fulfillment?.find((item) => item.type === "Delivery");
  return (
    <div className="container mx-auto my-8">
      {loading && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            opacity: 0.5,
            zIndex: 10,
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} />
        </Box>
      )}
      <div className="flex justify-between">
        <BackNavigationButton onClick={() => navigate("/orders")} />
        <div>
          <Button
            className="!capitalize my-2"
            variant="contained"
            onClick={() => navigate(`/issue/${order?.orderId}`)}
            sx={{ backgroundColor: "#1890ff" }}
          >
            View All Issues
          </Button>
          {order?.state !== "Cancelled" && order?.state !== "Created" && (
            <Button
              className="!capitalize"
              variant="contained"
              onClick={() => navigate(`/raise-issue/${order?.orderId}`)}
              sx={{ marginLeft: 1, backgroundColor: "#1890ff" }}
            >
              Raise Issue
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className={`${cardClass} my-4 p-3`} style={{ width: "100%" }}>
          <div className="flex justify-between mb-2 mx-4">
            <p className="font-semibold">Order Summary</p>
            <div style={{ display: "flex", direction: "row", gap: "14px" }}>
              <span className="bg-slate-100 p-2 rounded-lg">
                <p className="font-normal text-amber-400">
                  {order?.state}
                </p>
              </span>
            </div>
          </div>
          <Divider orientation="horizontal" />
          <div className="flex justify-between" style={{ width: "100%" }}>
            <div className="px-4" style={{ width: "49%" }}>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Order ID</p>
                <p className="font-normal">{order?.orderId}</p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Delivery Status</p>
                <p className="font-normal">{order && rtoStatus ? rtoStatus?.status : deliveryStatus?.status}</p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Created On</p>
                <p className="font-normal">
                  {convertDateInStandardFormat(order?.createdAt)}
                </p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Modified On</p>
                <p className="font-normal">
                  {convertDateInStandardFormat(order?.updatedAt)}
                </p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Order Status</p>
                <p className="font-normal">{order?.state}</p>
              </div>
              <div className="flex justify-between mt-3 mb-3">
                <p className="font-medium">Buyer name</p>
                <p className="text-md font-normal">{order?.billingAddress?.name}</p>
              </div>
            </div>
            <Divider orientation="vertical" />
            <div className={`px-4`} style={{ width: "49%" }}>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Transaction Id</p>
                <p className="font-normal">{order?.txId}</p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Payment Method</p>
                <p className="font-normal">{order?.paymentType}</p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Total Base Price</p>
                <p className="font-normal">
                  {parseFloat(total_base_cost).toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Total Taxes</p>
                <p className="font-normal">{total_tax_cost}</p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Total Delivery Fee</p>
                <p className="font-normal">
                  {parseFloat(delivery_charges).toFixed(2)}
                </p>
              </div>
              <div className="flex justify-between mt-3">
                <p className="font-medium">Total Price</p>
                <p className="font-normal">
                  {total_order_price
                    ? parseFloat(total_order_price).toFixed(2)
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${cardClass}`}>
          <OrderItemsSummaryCard
            orderItems={order?.linkedOrderItems}
            order={order}
          />
        </div>
        <div className={`${cardClass} my-4 py-4 px-8`}>
          <div className="flex h-full">
            <div className="flex-1 mr-8">
              <p className="font-semibold mb-2">Delivery Address</p>
              <div className="flex flex-col justify-between my-3">
                <p className="font-medium">
                  {getShortAddress(delivery_info)}
                </p>
                <p>{delivery_info?.state}</p>
                <p>{delivery_info?.areaCode}</p>
              </div>
            </div>
            <Divider orientation="vertical" />
            <div className="flex-1 ml-8">
              <p className="font-semibold mb-2">Billing Address</p>
              <div className="flex flex-col justify-between my-3">
                <p className="font-medium">
                  {getShortAddress(order?.billingAddress)}
                </p>
                <p>{order?.billingAddress?.state}</p>
                <p>{order?.billingAddress?.areaCode}</p>
              </div>
            </div>
            <Divider orientation="vertical" />
            <div className="flex-1 ml-8">
              <p className="font-semibold mb-2">Customer Details</p>
              <div className="flex items-center">
                <p className="font-semibold">Name : &nbsp;</p>
                <p className="font-medium">
                  {order?.endPersonName}
                </p>
              </div>
              <div className="flex items-center">
                <p className="font-semibold">Phone : &nbsp;</p>
                <p className="font-medium">
                  +91 {order?.endContactPhone}
                </p>
              </div>
              <div className="flex items-center">
                <p className="font-semibold">Email : &nbsp;</p>
                <p className="font-medium">
                  {order?.endContactPhone}
                </p>
              </div>
            </div>
            <Divider orientation="vertical" />
            {order?.agent &&
              <div className="flex-1 ml-8">
                <p className="font-semibold mb-2">Agent Details</p>
                <div className="flex items-center">
                  <p className="font-semibold">Name : &nbsp;</p>
                  <p className="font-medium">
                    {order?.agent?.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="font-semibold">Phone : &nbsp;</p>
                  <p className="font-medium">
                    +91 {order?.agent?.phone}
                  </p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderItemsSummaryCard = (props) => {
  let order_items = [];
  props.orderItems?.map((item) => {
    order_items.push(item);
  });
  let cols = [
    { id: "descriptorName", align: "left", minWidth: 50, label: "Items Summary" },
    { id: "priceValue", align: "center", minWidth: "50", label: "Price" },
    { id: "quantityCount", align: "center", minWidth: "auto", label: "Qty" },
    { id: "quantityMeasureUnit", align: "center", minWidth: "auto", label: "Qty Measure Unit" },
  ];

  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    return (
      <>
        <TableRow {...otherProps}>
          <TableCell sx={{ borderBottom: "none" }}></TableCell>
          {children}
        </TableRow>
      </>
    );
  };

  return (
    <div>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {cols.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align}
                    style={{
                      minWidth: col.minWidth,
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {order_items?.map((order_item, index) => {
                return (
                  <ExpandableTableRow
                    key={index}
                  >
                    {cols.map((col) => {
                      return (
                        <TableCell align={col.align} sx={{ borderBottom: "none" }}>
                          {col.id == "descriptorName" ? (
                            <div className="flex items-center">
                              <div className="flex flex-col">
                                <span style={{ fontWeight: 600 }}>
                                  {order_item?.descriptorName}
                                </span>
                              </div>
                            </div>
                          ) : col.id === "priceValue" ? (
                            <div>
                              ₹ {order_item?.priceValue?.toFixed(2).toLocaleString()}
                            </div>
                          ) : col.id === "quantityCount" ? (
                            <span>{order_item?.quantityCount}</span>
                          ) : col.id === "quantityMeasureUnit" ? (
                            <span>{order_item?.quantityMeasureUnit}</span>
                          ) : null}
                        </TableCell>
                      );
                    })}
                  </ExpandableTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default OrderDetails;
