import React, { useEffect, useState } from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { getCall } from '../../../Api/axios';
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import cogoToast from "cogo-toast";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

function OrderTimeline({ orderId, fulfillmentId }) {
    const [fulfillment, setFulfillment] = useState([]);
    const [loading, setLoading] = useState(true);

    const getFulfillment = async () => {
        setLoading(true);
        const url = `/api/v2/dashboard/fulfillment-history?limit=10&page=0&orderId=${orderId}`;
        getCall(url).then((resp) => {
            setFulfillment(resp.message);
            setLoading(false);
        }).catch((error) => {
            cogoToast.error(error.message);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (orderId) getFulfillment();
    }, [orderId]);

    // Sort fulfillment by createdAt in ascending order
    const sortedFulfillment = fulfillment
        .filter((t) => t.fulfillmentId === fulfillmentId) // Filter for matching fulfillmentId
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)); // Sort by date ascending

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}
            <div className="App" style={{ width: "450px" }}>
                <VerticalTimeline layout="1-column">
                    {sortedFulfillment?.map((t, i) => {
                        const contentStyle = { background: '#1890ff', color: '#fff', height: "60px", marginBottom: '5px' }; // Reduce bottom margin
                        const arrowStyle = { borderRight: '7px solid rgb(33, 150, 243)', color: "#1890ff" };
                        return (
                            <VerticalTimelineElement
                                key={i}
                                className="vertical-timeline-element--work"
                                contentStyle={contentStyle}
                                contentArrowStyle={arrowStyle}
                                position="right"
                                iconStyle={{ background: '#1890ff', color: '#fff', marginBottom: '5px', borderColor: '#ff0000' }} // Changed icon circle color to red
                                style={{ marginBottom: '10px !important' }}
                                icon={<LocalShippingIcon />}
                            >
                                <h3 className="vertical-timeline-element-title font-semibold">{t.status}</h3>
                                <h3 className="vertical-timeline-element-title">{convertDateInStandardFormat(t.createdAt)}</h3>
                            </VerticalTimelineElement>
                        );
                    })}
                </VerticalTimeline>
            </div>
        </>
    );
}

export default OrderTimeline;
