import React from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";

const StyledTableCell = styled(TableCell)({
    "&.MuiTableCell-root": {
        fontWeight: "bold",
    },
});

export default function RSFListingTable(props) {
    const {
        page,
        rowsPerPage,
        totalRecords,
        handlePageChange,
        handleRowsPerPageChange,
    } = props;

    const navigate = useNavigate();
    const onPageChange = (event, newPage) => {
        handlePageChange(newPage);
    };

    const onRowsPerPageChange = (event) => {
        handleRowsPerPageChange(parseInt(event.target.value, 10));
        handlePageChange(0);
    };

    const renderColumn = (row, column) => {
        switch (column.id) {
            case "network_order_id":
                return <span>{row.network_order_id}</span>;
            case "collector_id":
                return <span>{row.collector_id}</span>;
            case "receiver_id":
                return <span>{row.receiver_id}</span>;
            case "order_state":
                return <span>{row.order_state}</span>;
            case "order_created_date_time":
                return <span>{convertDateInStandardFormat(row.order_created_date_time)}</span>;
            case "item_value":
                return <span>{row.item_value}</span>;
            case "shipping_charge":
                return <span>{row.shipping_charge}</span>;
            case "packaging_charges":
                return <span>{row.packaging_charges}</span>;
            case "convenience_charges":
                return <span>{row.convenience_charges}</span>;
            case "total_order_value":
                return <span>{row.total_order_value}</span>;
            case "buyer_finder_fee":
                return <span>{row.buyer_finder_fee}</span>;
            case "withholding_amount":
                return <span>{row.withholding_amount}</span>;
            case "tsc":
                return <span>{row.tsc}</span>;
            case "tds":
                return <span>{row.tds}</span>;
            case "deduction_by_collector":
                return <span>{row.deduction_by_collector}</span>;
            case "settlement_amount":
                return <span>{row.settlement_amount}</span>;
            case "difference_amount":
                return <span>{row.difference_amount}</span>;
            case "return_window":
                return <span>{row.return_window}</span>;
            case "beneficiary_ifsc":
                return <span>{row.beneficiary_ifsc}</span>;
            case "collector_ifsc":
                return <span>{row.collector_ifsc}</span>;
            case "created_date_time":
                return <span>{convertDateInStandardFormat(row.created_date_time)}</span>;
            default:
                break;
        }
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column) => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        backgroundColor: "#4B92E7",
                                        color: "#fff",
                                    }}
                                    className="font-medium"
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row, index) => {
                            return (
                                <TableRow
                                    style={{ cursor: "pointer" }}
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                // onClick={() => {
                                //   navigate(`/application/orders/${row?._id}`);
                                // }}
                                >
                                    {props.columns.map((column, idx) => {
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {renderColumn(row, column)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Paper>
    );
}
