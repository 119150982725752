export const IGM_CODES = [
  { Category: 'ORDER', SubCategory: 'ORD01', Name: 'Order not received' },
  { Category: 'ORDER', SubCategory: 'ORD02', Name: 'Quality issue' },
  { Category: 'ORDER', SubCategory: 'ORD03', Name: 'Delayed delivery' },
  { Category: 'ORDER', SubCategory: 'ORD04', Name: 'Invoice missing' },
  { Category: 'ORDER', SubCategory: 'ORD05', Name: 'Store not responsive' },
  { Category: 'ORDER', SubCategory: 'ORD06', Name: 'Price change' },
  { Category: 'ORDER', SubCategory: 'ORD07', Name: 'Lower price available' },

  { Category: 'ITEM', SubCategory: 'ITM01', Name: 'Missing items' },
  { Category: 'ITEM', SubCategory: 'ITM02', Name: 'Quantity issue' },
  { Category: 'ITEM', SubCategory: 'ITM03', Name: 'Item mismatch' },
  { Category: 'ITEM', SubCategory: 'ITM04', Name: 'Quality issue' },
  { Category: 'ITEM', SubCategory: 'ITM05', Name: 'Expired item' },
  { Category: 'ITEM', SubCategory: 'ITM06', Name: 'Incorrectly marked as returned' },

  { Category: 'FULFILLMENT', SubCategory: 'FLM01', Name: 'Wrong delivery address' },
  { Category: 'FULFILLMENT', SubCategory: 'FLM02', Name: 'Delay in delivery' },
  { Category: 'FULFILLMENT', SubCategory: 'FLM03', Name: 'Delayed delivery' },
  { Category: 'FULFILLMENT', SubCategory: 'FLM04', Name: 'Packaging' },
  { Category: 'FULFILLMENT', SubCategory: 'FLM08', Name: 'Incorrectly marked as delivered' },

  { Category: 'AGENT', SubCategory: 'AGT01', Name: 'Agent behavioral issue' },
  
  { Category: 'PAYMENT', SubCategory: 'PMT01', Name: 'Refund not received' },
  { Category: 'PAYMENT', SubCategory: 'PMT02', Name: 'Underpaid' },
  { Category: 'PAYMENT', SubCategory: 'PMT03', Name: 'Overpaid' },
  { Category: 'PAYMENT', SubCategory: 'PMT04', Name: 'Notpaid' },
];
