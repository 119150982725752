import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import FilterComponent from '../../Shared/FilterComponent';
import CircularProgress from "@mui/material/CircularProgress";
import RequestLogsTable from './RequestLogsTable';

const columns = [
    {
        id: "action",
        label: "Action",
        minWidth: 100,
        align: "left",
    },
    {
        id: "request",
        label: "Request",
        minWidth: 200,
        align: "left",
    },
    {
        id: "response",
        label: "Response",
        minWidth: 200,
        align: "left",
    },
];
const filterFields = [
    {
        id: "action",
        title: "",
        placeholder: "Action",
        type: "select",
        variant: "standard",
        options: [
            { value: "search", label: "search" },
            { value: "on_search", label: "on_search" },
            { value: "init", label: "init" },
            { value: "on_init", label: "on_init" },
            { value: "update", label: "update" },
            { value: "on_update", label: "on_update" },
            { value: "cancel", label: "cancel" },
            { value: "on_cancel", label: "on_cancel" },
            { value: "status", label: "status" },
            { value: "on_status", label: "on_status" },
            { value: "track", label: "track" },
            { value: "on_track", label: "on_track" },
            { value: "issue", label: "issue" },
            { value: "on_issue", label: "on_issue" },
        ],
    },
    {
        id: "bpp_id",
        title: "",
        placeholder: "Bpp Id",
        type: "input",
        variant: "standard",
    },
    {
        id: "transaction_id",
        title: "",
        placeholder: "Transaction Id",
        type: "input",
        variant: "standard",
    },
    {
        id: "message_id",
        title: "",
        placeholder: "Message Id",
        type: "input",
        variant: "standard",
    },
];
const RequestLogs = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [columnList, setColumnList] = useState(columns);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, setFilters] = useState({
        action: '', transaction_id: '', message_id: '', bpp_id: ''
    });

    const fetchData = async () => {
        setLoading(true);
        const { data } = await axios.get(`${process.env.REACT_APP_REQUEST_LOGS_BASE_URL}protocol/request-logs`, {
            params: {
                page_number: page + 1,
                pageSize: rowsPerPage,
            },
        });
        setData(data.data);
        setTotalRecords(data.count);
        setLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, [page, rowsPerPage]);

    const onReset = () => {
        setPage(0)
        setFilters({ action: '', transaction_id: '', message_id: '', bpp_id: '' });
        fetchData()
    };

    const onFilter = async (field, value) => {
        setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_REQUEST_LOGS_BASE_URL}protocol/request-logs`, {
                params: {
                    page_number: page,
                    pageSize: rowsPerPage,
                    ...filters
                },
            });
            setData(data.data);
            setTotalRecords(data.count);
        } catch (error) {
        }
    };

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}

            <div className="container mx-auto py-8">
                <Box>
                    <FilterComponent
                        fields={filterFields}
                        state={filters}
                        stateHandler={setFilters}
                        onReset={onReset}
                        onFilter={onFilter}
                    />
                    <div className="container mx-auto">
                        <RequestLogsTable
                            columns={columnList}
                            data={data}
                            totalRecords={totalRecords}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handlePageChange={(val) => setPage(val)}
                            handleRowsPerPageChange={(val) => setRowsPerPage(val)}
                        />
                    </div>
                </Box>
            </div >
        </>
    )
};

export default RequestLogs;
