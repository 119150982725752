import React from 'react';
import { useState, useEffect } from "react";
import RSFListingTable from "./RSFListingTable"
import { getCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const columns = [
    {
        id: "network_order_id",
        label: "Network Order ID",
        minWidth: 100,
        align: "left",
    },
    {
        id: "collector_id",
        label: "Collector ID",
        minWidth: 100,
        align: "left",
    },
    {
        id: "receiver_id",
        label: "Receiver ID",
        minWidth: 80,
        align: "left",
    },
    {
        id: "order_state",
        label: "Order State",
        minWidth: 100,
        align: "left",
    },
    {
        id: "order_created_date_time",
        label: "Order Created Date",
        minWidth: 100,
        format: (value) => new Date(value).toLocaleString("en-US"),
        align: "left",
    },
    {
        id: "item_value",
        label: "Item Value",
        minWidth: 100,
        align: "left",
    },
    {
        id: "shipping_charge",
        label: "Shipping Charge",
        minWidth: 70,
        align: "left",
    },
    {
        id: "packaging_charges",
        label: "Packaging Charges",
        minWidth: 70,
        align: "left",
    },
    {
        id: "convenience_charges",
        label: "Convenience Charges",
        minWidth: 70,
        align: "left",
    },
    {
        id: "total_order_value",
        label: "Total Order Value",
        minWidth: 70,
        align: "left",
    },
    {
        id: "buyer_finder_fee",
        label: "Buyer Finder Fee",
        minWidth: 70,
        align: "left",
    },
    {
        id: "withholding_amount",
        label: "Withholding Amount",
        minWidth: 70,
        align: "left",
    },
    {
        id: "tsc",
        label: "TSC",
        minWidth: 70,
        align: "left",
    },
    {
        id: "tds",
        label: "TDS",
        minWidth: 70,
        align: "left",
    },
    {
        id: "deduction_by_collector",
        label: "Deduction by Collector",
        minWidth: 70,
        align: "left",
    },
    {
        id: "settlement_amount",
        label: "Settlement Amount",
        minWidth: 70,
        align: "left",
    },
    {
        id: "difference_amount",
        label: "Difference Amount",
        minWidth: 100,
        align: "left",
    },
    {
        id: "return_window",
        label: "Return Window",
        minWidth: 70,
        align: "left",
    },
    {
        id: "beneficiary_ifsc",
        label: "Beneficiary IFSC",
        minWidth: 100,
        align: "left",
    },
    {
        id: "collector_ifsc",
        label: "Collector IFSC",
        minWidth: 100,
        align: "left",
    },
    {
        id: "created_date_time",
        label: "Created On",
        minWidth: 100,
        format: (value) => new Date(value).toLocaleString("en-US"),
        align: "left",
    }
];

export default function RSFListing() {
    const [rsf, setRsf] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [columnList, setColumnList] = useState(columns);
    const [loading, setLoading] = useState(true);

    const getRsf = async () => {
        setLoading(true);
        const url = `/api/v2/dashboard/payment?limit=${rowsPerPage}&page=${page}`;
        try {
            const res = await getCall(url);
            let data = res.message;
            setRsf(data);
            setTotalRecords(res.total)
            setLoading(false);
        } catch (error) {
            cogoToast.error(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getRsf()
    }, [page, rowsPerPage]);

    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}
            <div className="container mx-auto py-14">
                <RSFListingTable
                    columns={columnList}
                    data={rsf}
                    totalRecords={totalRecords}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(val) => setPage(val)}
                    handleRowsPerPageChange={(val) => setRowsPerPage(val)}
                />
            </div>
        </>
    );
}
