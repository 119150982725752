import React from 'react';
import { useState, useEffect } from "react";
import ApiLogsListingTable from "./ApiLogsTable"
import { getCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import useCancellablePromise from "../../../Api/cancelRequest";
import FilterComponent from "../../Shared/FilterComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function ApiLogsListing() {

    const filterFields = [
        {
            id: "retailOrderId",
            title: "",
            placeholder: "Order Id",
            type: "input",
            variant: "standard",
        },
        {
            id: "orderId",
            title: "",
            placeholder: "Logistic Order Id",
            type: "input",
            variant: "standard",
        },
        {
            id: "txnId",
            title: "",
            placeholder: "Transaction Id",
            type: "input",
            variant: "standard",
        },
    ];

    const columns = [
        {
            id: "api",
            label: "API",
            minWidth: 80,
            align: "left",
        },
        {
            id: "responseStaus",
            label: "Response",
            minWidth: 80,
            align: "left",
        },
        {
            id: "payload",
            label: "Payload",
            minWidth: 50,
            align: "left",
        },
        {
            id: "bppId",
            label: "Bpp Id",
            minWidth: 100,
            align: "left",
        },
        {
            id: "orderId",
            label: "Order Id",
            minWidth: 100,
            align: "left",
        },
        {
            id: "logisticsOrderId",
            label: "Logistic Order Id",
            minWidth: 100,
            align: "left",
        },
        {
            id: "txnId",
            label: "Transaction Id",
            minWidth: 100,
            align: "left",
        }
    ];

    const respondentActionColumns = [
        {
            id: "type",
            label: "Action",
            minWidth: 180,
            align: "left",
        },
        {
            id: "responseStaus",
            label: "Status Code",
            minWidth: 180,
            align: "left",
        },
        {
            id: "ack",
            label: "ACK",
            minWidth: 180,
            align: "left",
        },
        {
            id: "error",
            label: "Error",
            minWidth: 180,
            align: "left",
        },
        {
            id: "timestamp",
            label: "Created At",
            minWidth: 180,
            align: "left",
            format: (value) => new Date(value).toLocaleDateString(),
        },
        {
            id: "txnId",
            label: "Transaction Id",
            minWidth: 150,
            align: "left",
        }
    ];

    const { cancellablePromise } = useCancellablePromise();
    const [apiLogs, setApiLogs] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [columnList, setColumnList] = useState(columns);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        orderId: "",
        retailOrderId: "",
        txnId: "",
    });

    const getApiLogs = async () => {
        setLoading(true);
        const url = `/api/v2/dashboard/request-logs?limit=${rowsPerPage}&page=${page}`;
        try {
            const res = await getCall(url);
            setApiLogs(res.message);
            setTotalRecords(res.total);
            setLoading(false);
        } catch (error) {
            cogoToast.error(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        getApiLogs()
    }, [page, rowsPerPage]);

    const onReset = () => {
        setFilters({ orderId: "", retailOrderId: "", txnId: "" });
        getApiLogs();
    };

    const onFilter = async () => {
        const filterParams = [];
        if (filters.orderId.trim() !== "") {
            filterParams.push(`orderId=${encodeURIComponent(filters.orderId)}`);
        }
        if (filters.retailOrderId.trim() !== "") {
            filterParams.push(`retailOrderId=${encodeURIComponent(filters.retailOrderId)}`);
        }
        if (filters.txnId.trim() !== "") {
            filterParams.push(`txnId=${encodeURIComponent(filters.txnId)}`);
        }

        const queryString = filterParams.join("&");
        const url = `api/v2/dashboard/request-logs?limit=${rowsPerPage}&page=${page}&${queryString}`;
        try {
            const res = await cancellablePromise(getCall(url));
            setApiLogs(res.message);
            setTotalRecords(res.total);
        } catch (error) {
            cogoToast.error(error.message);
        }
    };
    return (
        <>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        opacity: 0.5,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress sx={{ color: "#fff" }} />
                </Box>
            )}
            <div className="container mx-auto py-8">
                <FilterComponent
                    fields={filterFields}
                    state={filters}
                    stateHandler={setFilters}
                    onReset={onReset}
                    onFilter={onFilter}
                />
                <ApiLogsListingTable
                    columns={columnList}
                    respondentActionColumns={respondentActionColumns}
                    data={apiLogs}
                    totalRecords={totalRecords}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(val) => setPage(val)}
                    handleRowsPerPageChange={(val) => setRowsPerPage(val)}
                />
            </div>
        </>
    );
}
